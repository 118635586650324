.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__slides, .glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  touch-action: pan-Y;
  transform-style: preserve-3d;
  white-space: nowrap;
  width: 100%;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.glide__slide, .glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  white-space: normal;
  width: 100%;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  color: #fff;
  cursor: pointer;
  opacity: 1;
  text-shadow: 0 .25em .5em #0000001a;
  text-transform: uppercase;
  z-index: 2;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  height: 9px;
  width: 9px;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:focus, .glide__bullet:hover {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

hr {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

[type="button"].webkit-none {
  -webkit-appearance: none;
}

.home-container {
  --tw-translate-x: -50%;
  transform: var(--tw-transform);
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
}

.nav-top {
  height: 16px;
}

nav {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  padding: .25rem .5rem;
  display: flex;
}

@media (min-width: 768px) {
  nav {
    justify-content: space-between;
  }
}

nav .nav-title {
  font-family: Open Sans, sans-serif;
}

nav .hamburger {
  margin-left: .5rem;
  display: block;
  position: absolute;
  left: 0;
}

@media (min-width: 768px) {
  nav .hamburger {
    display: none;
  }
}

.carousel-container {
  height: auto;
  width: 100%;
}

.carousel-container .glide__slide img {
  height: auto;
  width: auto;
  margin: 0 auto;
}

.social-media {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  justify-content: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
}

.social-media a img {
  height: auto;
  width: 40px;
  margin-left: .75rem;
  margin-right: .75rem;
}

.social-media a.mynavi-width {
  margin-left: 50px;
}

.social-media a.mynavi-width img {
  width: 140px;
}

.fuji-holdings {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-bottom: 1.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.fuji-holdings .title {
  text-align: center;
  margin-bottom: .5rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.fuji-holdings .grid {
  grid-template-rows: repeat(4, 52px);
}

.fuji-holdings .logos {
  width: 100%;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .75rem;
  display: grid;
}

@media (min-width: 768px) {
  .fuji-holdings .logos {
    width: 50%;
  }
}

.fuji-holdings .logos img {
  height: 30px;
  width: auto;
  margin: 0 auto;
}

.fuji-holdings .logos a div {
  text-align: center;
  font-size: .625rem;
  font-weight: 700;
}

.fuji-holdings .fuji-map {
  width: 100%;
  margin-top: 1.25rem;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .fuji-holdings .fuji-map {
    width: 50%;
    margin-top: 0;
  }
}

.fuji-holdings .fuji-map {
  padding-top: 75%;
}

.fuji-holdings .fuji-map iframe {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.home-banner .new-graduates {
  position: relative;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.truck-total {
  --tw-text-opacity: 1;
  color: rgba(224, 1, 63, var(--tw-text-opacity));
  text-align: center;
  width: 100%;
}

@media (min-width: 768px) {
  .truck-total {
    width: 50%;
  }
}

.truck-total .used-trucks-button button {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  background-color: #036eb8;
  padding-left: .5rem;
  padding-right: .5rem;
}

.trucks {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: .5rem;
  margin-top: 1.25rem;
  padding-bottom: 1.5rem;
  display: grid;
}

@media (min-width: 768px) {
  .trucks {
    margin-top: 0;
  }
}

.trucks {
  width: 300px;
}

.trucks img {
  height: 60px;
  width: auto;
}

.news {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: Open Sans, sans-serif;
}

.news .iframe-holder {
  -webkit-overflow-scrolling: touch;
  height: 185px;
  overflow-y: auto;
}

.news .iframe-holder iframe {
  height: 100%;
  width: 100%;
}

footer {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  height: 58px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: .75rem;
  line-height: 5;
}

@media (min-width: 768px) {
  .carousel-container {
    height: 300px;
  }

  .carousel-container .glide__slide img {
    height: 300px;
    width: auto;
  }

  .fuji-holdings .fuji-map {
    padding-top: 36%;
  }

  .trucks {
    width: 100%;
  }

  .home-banner {
    position: relative;
  }

  .home-banner .new-graduates {
    position: absolute;
    inset: auto 28px 28px auto;
    transform: translateX(0);
  }
}

@media (min-width: 1024px) {
  .carousel-container, .fuji-holdings, .home-banner, .nav-top, .news, .social-media, .trucks-section, footer, nav {
    width: 840px;
    margin: 0 auto;
  }
}

/*# sourceMappingURL=index.98dfdbb4.css.map */
