@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import "./colors.scss";

hr {
    @apply
        border-black;
}

[type='button'].webkit-none {
    -webkit-appearance: none;
}

.home-container {
    @apply
        absolute
        top-0
        left-1/2
        transform 
        -translate-x-1/2
        w-full;
}

/* Nav */
.nav-top {
    height: 16px;
}

nav {
    @apply
        bg-white
        text-black
        flex
        justify-center
        md:justify-between
        items-center
        px-2
        py-1;
    .nav-title {
        font-family: 'Open Sans', sans-serif;        
    }
    .hamburger {
        @apply
            absolute
            left-0
            block
            ml-2
            md:hidden;
    }    
}

/* Carousel */
.carousel-container {
    @apply
        w-full
        h-auto;
    .glide__slide {
        img {
            @apply
                w-auto
                h-auto;
            margin: 0 auto;
        }
    }
}

/* Social Media */
.social-media {
    @apply
        py-5
        flex
        justify-center
        bg-white;
    a {
        img {
            @apply
                mx-3
                h-auto;
            width: 40px;
        }
        &.mynavi-width {
            margin-left: 50px;
            img {
                width: 140px;
            }
        }
    }
}

/* Fuji Holdings */
.fuji-holdings {
    @apply 
        bg-white
        px-5
        pb-6;
    .title {
        @apply 
            text-center 
            mb-2 
            text-xl;
        font-family: 'Open Sans', sans-serif;    
    }
    .grid {
        grid-template-rows: repeat(4, 52px);
    }

    /* Logos */
    .logos {
        @apply 
            w-full
            md:w-1/2
            grid
            gap-3
            grid-cols-3;
        img {
            @apply
                w-auto
                my-0
                mx-auto;
            height: 30px;
        }
        a div {
            @apply
                text-center
                font-bold;
            font-size: .625rem;
        }
    }

    /* Map */ 
    .fuji-map {
        @apply 
            mt-5
            md:mt-0
            w-full
            md:w-1/2
            relative
            overflow-hidden;
        padding-top: 75%;
        iframe {
            @apply
                absolute
                top-0
                left-0
                w-full
                h-full;
        }
    }
}

/* Recruitment */
.home-banner {
    .new-graduates {
        position: relative;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
    }
}

/* Trucks */
.truck-total {
    @apply
    text-custom-red 
    w-full 
    md:w-1/2 
    text-center;
    .used-trucks-button {
        button {
            @apply
                px-2
                text-white;
            background-color:#036EB8
        }
    }
}

.trucks {
    @apply
        pb-6
        mt-5
        md:mt-0
        grid
        gap-2
        grid-cols-3;
    width: 300px;
    img {
        @apply
            w-auto;
        height: 60px;
    }
}

/* News */
.news {
    @apply 
        px-5
        bg-white;
    font-family: 'Open Sans', sans-serif;    
    .iframe-holder {
        height: 185px;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;        
        iframe {
            @apply
                h-full
                w-full;
        }
    }
}

footer {
    @apply
        px-6
        text-xs
        bg-white;
    height: 58px;
    line-height: 5;
}

/* Media Queries */
@media(min-width: 768px) {
    .carousel-container {
        height: 300px;
        .glide__slide {
            img {
                @apply
                    w-auto;
                height: 300px;
            }
        }        
    }
    .fuji-holdings {
        .fuji-map {
            padding-top: 36%;
        }
    }
    .trucks {
        @apply
            w-full;
    }
    .home-banner {
        @apply
            relative;
        .new-graduates {
            @apply
                absolute;
            top: auto;
            bottom: 28px;
            right: 28px;
            left: auto;
            transform: translateX(0);
        }
    }    
}

@media(min-width: 1024px) {
    .nav-top,
    nav,
    .carousel-container,
    .social-media,
    .fuji-holdings,
    .home-banner,
    .trucks-section,
    .news,
    footer {
        width: 840px;
        margin: 0 auto;
    }
}